<template>
  <div id="PageNotFound">
    <img class="img" src="https://static.growlytics.in/dashboard-assets/assets/img/404.jpg" />

    <div class="title">LOOKS LIKE YOU ARE LOST</div>
    <div class="description">The page you are looking for is not available!</div>

    <router-link :to="{ path: '/' }" replace>
      <el-button type="primary" round>Take Me Home</el-button>
    </router-link>
  </div>
</template>

<style lang="scss">
#PageNotFound {
  background: white;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;

  .img {
    width: 500px;
  }

  .title {
    font-size: 30px;
    margin-top: -60px;
    font-family: monospace;
  }

  .description {
    font-size: 12px;
    color: #555;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
</style>

<script>
import PageNotFound from './404Component';
export default PageNotFound;
</script>
