// import router from "../../router";

export default {
  name: '404',
  data() {
    return {};
  },
  methods: {},
  created() {}
};
